
import { FC, Fragment, useEffect, useState } from 'react'
import { Box, Button, Checkbox, CircularProgress, Container, CssBaseline, FormControlLabel, Grid, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material'
import { InputProps } from '../../types'
import { Connect, mapDispatchToProps } from '../../Redux';
import { Apis } from '../../helpers';
import queryString from 'query-string';
import { LoadingButton } from '@mui/lab';
import logo from '../../assets/images/logo.png'
import { theme } from '../../theme';
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { EventsEmit } from '../../configs/events';
import NoData from '../Common/NoData';
import { useNavigate } from 'react-router-dom';

interface StateProps {
    currentPage: 'Sign In' | 'Sign Up' | 'Sign Up Success' | 'Forgot Password' | 'Reset Password'
    inputs: InputProps[]
    loading: boolean
    submitting: boolean
    showPassword: boolean
    processing: boolean
    signUpError: string | null
}
interface Props {
    setRedux?: (data: any) => void
}
const Auth: FC<Props> = ({
    setRedux
}) => {
    const initialInputs: InputProps[] = [
        { label: 'First Name', value: '', field: 'firstName' },
        { label: 'Last Name', value: '', field: 'lastName' },
        { label: 'Email', value: '', type: 'email', field: 'email' },
        { label: 'Username', value: '', field: 'userName' },
        { label: 'Password', value: '', field: 'password', type: 'password' },
        { label: 'New Password', value: '', field: 'newPassword', type: 'password' },
        { label: 'Confirm Password', value: '', field: 'confirmPassword', type: 'password' },
        { label: 'Agree to ', value: false, field: 'agreeToTerms', type: 'checkbox' }
    ]
    const [state, setState] = useState<StateProps>({
        currentPage: 'Sign In',
        inputs: initialInputs,
        loading: false,
        submitting: false,
        showPassword: false,
        signUpError: null,
        processing: true
    })
    const { currentPage, loading, inputs, submitting, showPassword, signUpError, processing } = state
    const showInputs = (inputs.filter(q => (
        currentPage === 'Sign In' ? ['email', 'password'] :
            currentPage === 'Forgot Password' ? ['email'] :
                currentPage === 'Reset Password' ? ['newPassword', 'confirmPassword'] :
                    initialInputs.map(q => q.field).filter(q => !['newPassword', 'confirmPassword'].includes(q))
    ).includes(q.field)))
    const navigate = useNavigate()

    const changeInput = (input: string, obj: any) => {
        const Inputs = [...inputs]
        const inputIndex = inputs.findIndex(q => q.field === input)
        Inputs[inputIndex] = { ...Inputs[inputIndex], ...obj }
        setState({
            ...state,
            inputs: Inputs,
            submitting: false
        })
    }
    // const userAccessToken = lo
    //functions
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const obj: any = {}
        data.forEach((v, k) => obj[k] = v)
        if (currentPage === 'Sign Up' && !obj.agreeToTerms)
            changeInput('agreeToTerms', { error: 'You must agree to the terms and conditions of using this software.' })
        else if (currentPage === 'Reset Password' && obj.newPassword !== obj.confirmPassword)
            changeInput('confirmPassword', { error: 'Passwords do not match.' })
        else {
            obj.agreeToTerms = true
            setState({
                ...state,
                submitting: true
            })
            Apis({
                module: 'Users',
                action: currentPage,
                ...obj
            }, queryString.parse(window.location.search)?.reset_password_token as string).then((resp: any) => {
                const { error, nextPage, field, message, status } = resp
                if (error)
                    changeInput(field, { error })
                else if (nextPage)
                    setState({ ...state, currentPage: nextPage })
                else {
                    setState({ ...state, submitting: false })
                    if (setRedux)
                        setRedux({ ...resp })
                }
                if (message)
                    EventsEmit('app', {
                        info: {
                            message,
                            status
                        }
                    })
                if (['Reset Password', 'Sign In'].includes(currentPage)) {
                    const url = window.location.href;

                    const updatedUrl = url.replace(/([&?])reset_password_token=[^&]+(&|$)/, '$1');

                    window.history.replaceState({}, document.title, updatedUrl);
                }
            }).catch((e) => {
                console.log(e)
                EventsEmit('app', {
                    info: {
                        message: 'Something went wrong! Please check your internet connection.',
                        status: 'error'
                    }
                })
                setState({ ...state, submitting: false })
            })
        }
    };
    const activateAccount = (user_access: string, action: string) => {
        Apis({
            module: 'Users',
            action,
            user_access
        }).then((resp: any) => {
            const { error, message, nextPage } = resp
            if (error)
                setState({
                    ...state,
                    currentPage: 'Sign Up Success',
                    signUpError: message
                })
            else if (nextPage)
                setState({ ...state, currentPage: nextPage })
            else {
                if (setRedux)
                    setRedux({ ...resp })
                setState({
                    ...state,
                    loading: false,
                    currentPage: 'Sign In'
                })
                navigate('/properties')
            }
        }).catch((error) => {
            console.log(error)
            setState({
                ...state,
                loading: false,
                currentPage: 'Sign In'
            })
        })
    }

    useEffect(() => {
        const query = queryString.parse(window.location.search);
        if (query.user_access || query.reset_password_token) {
            setState({
                ...state,
                loading: true
            })

            activateAccount(
                (query.user_access || query.reset_password_token) as string,
                query.user_access ? 'activateUserAccount' : 'checkForgotPasswordRequest'
            )
        }
        else if (query.plan) {
            const { features } = query
            if (setRedux)
                setRedux({
                    loginSelectedPlan: {
                        plan: query.plan,
                        features: features ? (features as string).split(",") : []
                    }
                })
        }
        else if (query.signup)
            setState({ ...state, currentPage: 'Sign Up', processing: false })
        if (!query.signup)
            setTimeout(() => {
                setState({ ...state, processing: false })
            }, 2000);
        return () => {

        }
    }, [])

    return (
        <Container component="main" maxWidth="xs" sx={{
            minHeight: '100vh',
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            justifyContent: `center`,
            fontSize: `calc(10px + 2vmin)`,
            textAlign: 'center'
        }}>
            {
                processing ?
                    <NoData
                        loading={processing}
                    /> :
                    <Fragment>
                        <CssBaseline />
                        {
                            loading ?
                                <Box>
                                    <CircularProgress />
                                </Box> :

                                <Box
                                    sx={{
                                        marginTop: 8,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{
                                        mb: 5
                                    }}>
                                        <Link
                                            href={'/'}
                                            sx={{textDecoration:'none'}}
                                        >
                                            <img src={logo} style={{ height: 200, width: 200, objectFit: 'contain' }} />
                                            <Typography sx={{
                                                color: theme.lightTheme.palette.primary.main,
                                                fontSize: 20,
                                                fontWeight: 'bold',
                                                mt: -5
                                            }}>Sheltergram</Typography>
                                        </Link>
                                    </Box>
                                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, pb: 5 }}>
                                        {
                                            currentPage === 'Sign Up Success' ?
                                                <Box sx={{ mt: 5 }}>
                                                    <Typography>{signUpError || 'A confirmation link has been sent to your email with more instructions.'} </Typography>
                                                    <Button
                                                        onClick={() => setState({
                                                            ...state,
                                                            currentPage: 'Sign In',
                                                            signUpError: null
                                                        })}
                                                        sx={{
                                                            textTransform: 'none',
                                                            textDecoration: 'underline'
                                                        }}>Back to Sign In</Button>
                                                </Box> :
                                                <Fragment>

                                                    <Grid container spacing={2}>
                                                        {
                                                            showInputs.map(({ label, type, value, field, error }, i: number) => (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={currentPage === 'Sign Up' && [0, 1].includes(i) ? 6 : 12}
                                                                    key={field}
                                                                >
                                                                    {
                                                                        type === 'checkbox' ?
                                                                            <Fragment>
                                                                                <Box sx={{ fontSize: 14 }}>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox
                                                                                            value={value}
                                                                                            color="primary"
                                                                                            name={field}
                                                                                        />}
                                                                                        label={label}
                                                                                        onChange={() => changeInput(field, { error: null })}
                                                                                    />
                                                                                    <Link target={'_blank'} sx={{ ml: -1 }} href={'/terms_conditions'}>Terms & Conditions</Link> and <Link target={'_blank'} href={'/privacy_policies'}>Privacy Policies</Link>
                                                                                </Box>
                                                                                {error ? <Typography color={'error'} sx={{ fontSize: 16 }}>*{error}</Typography> : <Fragment />}
                                                                            </Fragment> :
                                                                            <TextField
                                                                                autoComplete="given-name"
                                                                                name={field}
                                                                                required
                                                                                fullWidth
                                                                                label={currentPage === 'Sign In' && field === 'email' ? 'Username or Email' : label}
                                                                                type={currentPage === 'Sign In' && field === 'email' ? 'text' : ['password', 'confirmPassword', 'newPassword'].includes(field) ? (showPassword ? 'text' : type) : (type || 'text')}
                                                                                autoFocus
                                                                                error={Boolean(error)}
                                                                                helperText={error}
                                                                                onFocus={() => changeInput(field, { error: null })}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        ['password', 'confirmPassword', 'newPassword'].includes(field) ?
                                                                                            <InputAdornment position="end">
                                                                                                <IconButton
                                                                                                    onClick={() => setState({
                                                                                                        ...state,
                                                                                                        showPassword: !showPassword
                                                                                                    })}
                                                                                                >
                                                                                                    {
                                                                                                        showPassword ?
                                                                                                            <VisibilityOffOutlined /> :
                                                                                                            <RemoveRedEyeOutlined />
                                                                                                    }
                                                                                                </IconButton>
                                                                                            </InputAdornment> :
                                                                                            <Fragment />
                                                                                    )
                                                                                }}
                                                                            />
                                                                    }
                                                                    {
                                                                        currentPage === 'Sign In' && field === 'password' ?
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'flex-end',
                                                                                mt: 2
                                                                            }}
                                                                            >
                                                                                <Button
                                                                                    sx={{
                                                                                        textTransform: 'none',
                                                                                        textDecoration: 'underline'
                                                                                    }}
                                                                                    onClick={() => setState({ ...state, currentPage: 'Forgot Password' })}
                                                                                >Forgot Password?</Button>
                                                                            </Box> :
                                                                            <Fragment />
                                                                    }

                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                    <LoadingButton
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                                                        loading={submitting}
                                                    >
                                                        {currentPage}
                                                    </LoadingButton>

                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item>
                                                            <Link
                                                                sx={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => setState({
                                                                    ...state,
                                                                    currentPage: currentPage === 'Sign In' ? 'Sign Up' : 'Sign In',
                                                                    inputs: initialInputs,
                                                                    showPassword: false
                                                                })} variant="body2">
                                                                {currentPage === 'Sign In' ? `Don't have an Account? Sign Up` : 'Already have an account? Sign in'}
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </Fragment>
                                        }
                                    </Box>
                                </Box>
                        }
                    </Fragment>
            }
        </Container>
    )
}

export default Connect(null, mapDispatchToProps)(Auth)