import { Box, Grid, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { theme } from '../../theme';
import service from '../../assets/images/service.png'
import service1 from '../../assets/images/service1.png'
import { KeyboardDoubleArrowRight } from '@mui/icons-material';

interface Props { }

const Services: FC<Props> = () => {


    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.3 } // Adjust threshold to determine when it triggers
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);
    const features = [
        "Free business listing for home-related services",
        "Increased visibility to potential clients",
        "Direct customer inquiries and bookings",
        "Ability to showcase reviews and ratings",
        "Seamless communication with clients",
        "Opportunity to offer special discounts and promotions",
        "Integration with digital payments for easy transactions",
        "Access to a marketplace for second-hand home-related items",
        "Networking opportunities with property owners and tenants",
        "Personalized business profile to showcase expertise",
        "Sheltergram's marketing support to attract more clients"
    ];


    return (
        <section id={'services'}>
            <Box
                ref={ref}
                sx={{
                    pl: { sx: 0, md: 10 },
                    mt: { sx: 5, md: 10 },
                    pb: 5,
                    overflowX: 'hidden'
                }}>
                <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
                    <Grid item xs={8} md={6} >
                        <motion.img
                            src={service}
                            alt="Your Image"
                            style={{
                                height: "70vh", // Reduce height dynamically
                                width: "auto",
                                maxHeight: 500, // Ensure it doesn't get too large
                                objectFit: "contain",
                                borderRadius: 30,
                                boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.3)"
                            }}
                            initial={{ opacity: 0, y: 50, rotate: -5 }}
                            animate={isVisible ? { opacity: 1, y: 0, rotate: -5 } : {}}
                            transition={{ duration: 0.8 }}
                        />
                        <motion.img
                            src={service1}
                            alt="Your Image"
                            style={{
                                height: "70vh",
                                width: "auto",
                                maxHeight: 500,
                                objectFit: "contain",
                                borderRadius: 30,
                                boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.3)",
                                marginTop: 20, // Add space between images
                            }}
                            initial={{ opacity: 0, y: 50, rotate: 5 }}
                            animate={isVisible ? { opacity: 1, y: 0, rotate: 5 } : {}}
                            transition={{ duration: 0.8 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <motion.div
                            initial={{ opacity: 0, x: 50 }}
                            animate={isVisible ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.8 }}
                        >
                            <Typography variant="h4" sx={{
                                ml: { xs: 3, sm: 10 },
                                fontWeight: 'bold',
                                color: theme.lightTheme.palette.primary.main,
                                mb: 5,
                                fontSize: { xs: 20, sm: 30 },
                            }}>
                                Grow Your Business
                            </Typography>
                            <Typography variant="h1" sx={{
                                ml: { xs: 3, sm: 10 },
                                fontWeight: 'bold',
                                mr: 10,
                                mb: 5,
                                fontSize: { xs: 40, sm: 50 }
                            }}>
                                Offer Your Home Services on Sheltergram & Grow Your Business
                            </Typography>
                            <Typography sx={{
                                ml: { xs: 3, sm: 10 },
                                fontSize: { xs: 14, sm: 22 },
                                mr: { xs: 0, sm: 12 },
                                fontWeight: 'bold'
                            }}>
                                With Sheltergram, you can:
                            </Typography>
                            {
                                features.map((desc, i) => (
                                    <Box
                                        key={i}
                                        sx={{ ml: { xs: 3, sm: 10 }, mt: 2, display: 'flex' }}
                                    >
                                        <KeyboardDoubleArrowRight sx={{ color: theme.lightTheme.palette.primary.main }} />
                                        <Typography>{desc}</Typography>
                                    </Box>
                                ))
                            }
                        </motion.div>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
}

export default Services;
