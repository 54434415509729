import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { motion } from "framer-motion";
import Show from "../Common/show";

const basicFeatures = [
    "Listing properties",
    "Rent collection",
    "Record keeping",
    "Tenant Reminder Notifications",
    "Tenant Screening and Onboarding"
];

const silverPlan = [
    "Debt recovery",
    "Maintenance",
    "Caretaking",
    "Sheltergram is the custodian of tenant deposit."
];

const pricingPlans = [
    {
        title: "Bronze Lite",
        price: "2.5%",
        description: "Landlord manages the property themselves using the platform.",
        backgroundImage: "linear-gradient(135deg, #f5e1c0 0%, #c8a777 100%)",
        includedFeatures: [
            "Rent collection",
            "Record keeping",
            "Tenant Reminder Notifications"
        ]
    },
    {
        title: "Bronze",
        price: "5%",
        description: "Landlord manages the property themselves using the platform.",
        backgroundImage: "linear-gradient(135deg, #e0b07e 0%, #a66a3c 100%)",
        includedFeatures: [...basicFeatures, "Payments are remitted immediately when the tenant pays"]
    },
    {
        title: "Silver",
        price: "7.5%",
        description: "Sheltergram handles the entire management.",
        backgroundImage: "linear-gradient(135deg, #dcdcdc 0%, #a8a8a8 100%)",
        includedFeatures: [...basicFeatures, "Payments are remitted immediately when the tenant pays", ...silverPlan]
    },
    {
        title: "Gold",
        price: "10%",
        description: "Sheltergram handles the entire management.",
        backgroundImage: "linear-gradient(135deg, #ffd700 0%, #b8860b 100%)",
        includedFeatures: [...basicFeatures, ...silverPlan, "Total payments are made on 5th of every month regardless of whether tenants have paid or not"]
    }
];

// Generate feature availability dynamically
const allFeatures = [...basicFeatures, "Payments are remitted immediately when the tenant pays", ...silverPlan, "Total payments are made on 5th of every month regardless of whether tenants have paid or not"];

const structuredPlans = pricingPlans.map(plan => ({
    ...plan,
    features: allFeatures.reduce((acc: any, feature) => {
        acc[feature] = plan.includedFeatures.includes(feature);
        return acc;
    }, {})
}));

const Plans = () => {
    return (
        <Box
            sx={{
                width: "100%",
                overflow: "hidden", // Prevents the entire page from scrolling horizontally
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                pt: 5,
                pb: 5,
                backgroundImage: "linear-gradient(135deg, #f9f9f0 0%, #c1c196 100%)",
                mb:1
            }}
        >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
                Pricing Plans
            </Typography>

            {/* Scrollable Container */}
            <Box
                sx={{
                    display: "flex",
                    gap: 3,
                    overflowX: "auto",
                    p: 2,
                    maxWidth: "90vw", // Constrain width to avoid affecting the whole page
                    whiteSpace: "nowrap", // Prevents wrapping
                    "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar
                    scrollbarWidth: "none", // Firefox
                }}
            >
                {pricingPlans.map((plan, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.2 }}
                    >
                        <Card sx={{
                            width: 300,
                            borderRadius: 3,
                            overflow: "hidden",
                            display: "inline-block", // Prevents cards from wrapping
                            verticalAlign: "top",
                            p: 2,
                            pt: 0,
                            boxShadow: "none"
                        }}>
                            <CardContent sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                pt: 0,
                            }}>
                                <Box sx={{ backgroundImage: plan.backgroundImage, pt: 1, pb: 1 }}>
                                    <Typography variant="h6" fontWeight="bold" textAlign="center">
                                        {plan.title}
                                    </Typography>
                                </Box>
                                <Typography mt={1} mb={1} fontWeight="bold" textAlign="center">
                                    <span style={{ fontSize: 30, fontWeight: 'bold' }}>{plan.price}</span><br />
                                    of Paid Rent
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        mt: 1,
                                        textAlign: "center",
                                        color: '#8b8b8b',
                                        fontWeight: 'bold',
                                        whiteSpace: "normal", // Allows text to wrap 
                                        wordWrap: "break-word", // Breaks long words if necessary 
                                        overflowWrap: "break-word",
                                    }}
                                >
                                    {plan.description}
                                </Typography>
                                <Box component="ul" sx={{ mt: 2, pl: 2, flex: 1 }}>
                                    {allFeatures.map((adv, i) => (
                                        <Box key={i} sx={{ display: 'flex', mt: 1.5, mb: 1.5 }}>
                                            <Show>
                                                <Show.When isTrue={plan.includedFeatures.includes(adv)}>
                                                    <CheckCircle sx={{ color: '#b7bf00' }} />
                                                </Show.When>
                                                <Show.Else>
                                                    <Cancel sx={{ color: '#ed0027' }} />
                                                </Show.Else>
                                            </Show>
                                            <Typography sx={{
                                                fontSize: { sx: 14, md: 16 },
                                                ml: 1.5,
                                                whiteSpace: "normal", // Allows text to wrap 
                                                wordWrap: "break-word", // Breaks long words if necessary 
                                                overflowWrap: "break-word",
                                            }} variant="body2">{adv}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </CardContent>
                        </Card>
                    </motion.div>
                ))}
            </Box>
        </Box>
    );
};

export default Plans;
