import { Box, Grid, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { theme } from '../../theme';
import property from '../../assets/images/property.png'
import stats from '../../assets/images/stats.png'
import { KeyboardDoubleArrowRight } from '@mui/icons-material';

interface Props { }

const Landlord: FC<Props> = () => {


    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.3 } // Adjust threshold to determine when it triggers
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);
    const features = [
        {
            title: "Get Tenants with Good Reviews",
            description: "Attract reliable tenants with verified rental histories."
        },
        {
            title: "Ensure Timely Rent Payments",
            description: "Tenants can access rent credit, reducing late payments."
        },
        {
            title: "Simplify Record-Keeping",
            description: "Easily track rent payments, expenses, and tenant details."
        },
        {
            title: "Streamline Communication",
            description: "Quickly connect with tenants through a centralized platform."
        },
        {
            title: "Track Maintenance Requests",
            description: "Stay updated on tenants who need repairs or maintenance."
        },
        {
            title: "List Properties for Free",
            description: "Unlike realtors, you can showcase your property at no cost."
        },
        {
            title: "And Much More!",
            description: "Designed to make property management stress-free and profitable."
        }
    ];


    return (
        <section id={'landlord'}>
            <Box ref={ref} sx={{
                pl: { sx: 0, md: 10 },
                mt: { sx: 5, md: 10 },
                pb: 5,
                overflowX: 'hidden'
            }}>
                <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
                    {/* Right Side Text & Boxes with Slide-in Effect */}
                    <Grid item xs={12} md={6}>
                        <motion.div
                            initial={{ opacity: 0, x: 50 }}
                            animate={isVisible ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.8 }}
                        >
                            <Typography variant="h4" sx={{
                                ml: { xs: 3, sm: 10 },
                                fontWeight: 'bold',
                                color: theme.lightTheme.palette.primary.main,
                                mb: 5,
                                fontSize: { xs: 20, sm: 30 },
                            }}>
                                Are You a Landlord or Property Agent
                            </Typography>
                            <Typography variant="h1" sx={{
                                ml: { xs: 3, sm: 10 },
                                fontWeight: 'bold',
                                mr: { sx: 0, md: 10 },
                                mb: 5,
                                fontSize: { xs: 40, sm: 50 }
                            }}>
                                Struggling to Find and Manage Reliable Tenants?
                            </Typography>
                            <Typography sx={{
                                ml: { xs: 3, sm: 10 },
                                fontSize: { xs: 14, sm: 22 },
                                mr: { xs: 0, sm: 12 },
                                fontWeight: 'bold'
                            }}>
                                With Sheltergram, you can:
                            </Typography>
                            {
                                features.map(({ title, description }, i) => (
                                    <Box
                                        key={i}
                                        sx={{ ml: { xs: 3, sm: 10 }, mt: 2, display: 'flex' }}
                                    >
                                        <KeyboardDoubleArrowRight sx={{ color: theme.lightTheme.palette.primary.main }} />
                                        <Typography><span style={{ fontWeight: 'bold' }}>{title}</span> - {description}</Typography>
                                    </Box>
                                ))
                            }
                        </motion.div>
                    </Grid>
                    {/* Left Side Image with Fade-in Effect */}
                    <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                        <motion.img
                            src={property}
                            alt="Your Image"
                            style={{
                                height: "70vh", // Reduce height dynamically
                                width: "auto",
                                maxHeight: 500, // Ensure it doesn't get too large
                                objectFit: "contain",
                                borderRadius: 30,
                                boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.3)"
                            }}
                            initial={{ opacity: 0, y: 50, rotate: -5 }}
                            animate={isVisible ? { opacity: 1, y: 0, rotate: -5 } : {}}
                            transition={{ duration: 0.8 }}
                        />
                        <motion.img
                            src={stats}
                            alt="Your Image"
                            style={{
                                height: "70vh",
                                width: "auto",
                                maxHeight: 500,
                                objectFit: "contain",
                                borderRadius: 30,
                                boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.3)",
                                marginTop: 20, // Add space between images
                            }}
                            initial={{ opacity: 0, y: 50, rotate: 5 }}
                            animate={isVisible ? { opacity: 1, y: 0, rotate: 5 } : {}}
                            transition={{ duration: 0.8 }}
                        />
                    </Grid>

                </Grid>
            </Box>
        </section>
    );
}

export default Landlord;
