import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { theme } from '../../theme';
import diffMonth from '../../assets/images/diff.png';
import { AddBusiness, CreditScore, ImageSearch } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100, damping: 10 } }
};

const Tenant = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.getElementById('tenant-section');
            if (section) {
                const rect = section.getBoundingClientRect();
                if (rect.top < window.innerHeight * 0.75) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const items = [
        {
            icon: <ImageSearch sx={{ fontSize: 120, color: '#f9f3c6' }} />,
            title: 'Find Your Perfect Rental Home',
            message: 'Browse through verified listings and discover the ideal rental that suits your needs and lifestyle.'
        },
        {
            icon: <AddBusiness sx={{ fontSize: 120, color: '#f9f3c6' }} />,
            title: 'Build a Strong Payment Record',
            message: 'Make timely rent payments to establish a solid financial reputation and unlock future benefits.'
        },
        {
            icon: <CreditScore sx={{ fontSize: 120, color: '#f9f3c6' }} />,
            title: 'Secure Rent Credit with Ease',
            message: 'Gain access to financial support when needed, ensuring you never fall behind on rent payments.'
        }
    ];

    return (
        <Box id="tenant-section" sx={{
            bgcolor: { sx: 'transparent', md: theme.lightTheme.palette.primary.main },
            mt: { sx: 0, md: 40 },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            pb: 15
        }}>
            {/* Main Content Box with Animation */}
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={isVisible ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1 }}
            >
                <section id={'tenant'}>

                    <Box sx={{ mt: { xs: 0, md: -40 }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* Grid container for large screens */}
                        <Grid container justifyContent="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <Grid item xs={12} sm={8} md={9}>
                                <Box sx={{
                                    backgroundColor: '#fff',
                                    boxShadow: { sx: 'none', md: '0px 4px 10px rgba(0, 0, 0, 0.4)' },
                                    borderRadius: 10,
                                    padding: 3,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    pt: 5,
                                    px: 5
                                }}>
                                    {/* Image Box */}
                                    <motion.div
                                        initial={{ opacity: 0, scale: 0.8 }}
                                        animate={isVisible ? { opacity: 1, scale: 1 } : {}}
                                        transition={{ duration: 1, ease: 'easeOut' }}
                                        style={{ maxWidth: "50%", height: "100%" }}
                                    >
                                        <img
                                            src={diffMonth}
                                            alt="Illustration"
                                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                        />
                                    </motion.div>

                                    {/* Text Box */}
                                    <Box sx={{ flex: 1, maxWidth: "50%", textAlign: 'left', p: 3 }}>
                                        <Typography variant="h4" sx={{
                                            fontWeight: 'bold',
                                            color: theme.lightTheme.palette.primary.main,
                                            mb: 3,
                                            fontSize: { xs: 20, sm: 25 },
                                        }}>
                                            Access Rent Credit With Ease
                                        </Typography>
                                        <Typography variant="h4" sx={{
                                            fontWeight: 'bold',
                                            fontSize: { xs: 30, sm: 40 },
                                            mb: 5
                                        }}>
                                            Pay Rent on Time, Even in Tough Months!
                                        </Typography>
                                        <Typography sx={{ fontSize: { xs: 14, sm: 16 } }}>
                                            Build a strong rent payment history to unlock credit opportunities from our investor-backed liquidity pool—secure, transparent, and hassle-free.
                                        </Typography>
                                        <Button variant={'contained'} sx={{ mt: 5, textTransform: 'none', fontSize: 18 }}>
                                            Learn how it works
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        {/* Flowing content for small screens */}
                        <Box sx={{
                            backgroundColor: '#fff',
                            boxShadow: { sx: 'none', md: '0px 4px 10px rgba(0, 0, 0, 0.4)' },
                            borderRadius: 10,
                            padding: 3,
                            display: { xs: 'flex', md: 'none' },
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            pt: 5,
                            px: 5
                        }}>
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={isVisible ? { opacity: 1, scale: 1 } : {}}
                                transition={{ duration: 1, ease: 'easeOut' }}
                                style={{ width: "100%", height: "auto", textAlign: 'center' }}
                            >
                                <img
                                    src={diffMonth}
                                    alt="Illustration"
                                    style={{ width: "100%", maxWidth: "400px", height: "auto", objectFit: "contain" }}
                                />
                            </motion.div>

                            <Typography variant="h4" sx={{
                                fontWeight: 'bold',
                                color: theme.lightTheme.palette.primary.main,
                                mb: 3,
                                fontSize: { xs: 20, sm: 30 },
                                mt: 2
                            }}>
                                Access Rent Credit With Ease
                            </Typography>
                            <Typography variant="h4" sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: 30, sm: 40 },
                                mb: 5
                            }}>
                                Pay Rent on Time, Even in Tough Months!
                            </Typography>
                            <Typography sx={{ fontSize: { xs: 14, sm: 22 } }}>
                                Build a strong rent payment history to unlock credit opportunities from our investor-backed liquidity pool—secure, transparent, and hassle-free.
                            </Typography>
                            <Button variant={'contained'} sx={{ mt: 5, textTransform: 'none', fontSize: 18 }}>
                                Learn how it works
                            </Button>
                        </Box>
                    </Box>
                </section>
            </motion.div>

            {/* Cards Section */}
            <Grid container spacing={4} justifyContent="center" sx={{ mt: 5 }}>
                {items.map((item, index) => (
                    <Grid item xs={11} sm={6} md={4}lg={3} key={index}>
                        <motion.div
                            initial="hidden"
                            animate={isVisible ? "visible" : "hidden"}
                            variants={cardVariants}
                            transition={{ delay: index * 0.2 }}
                        >
                            <Card sx={{ textAlign: 'center', p: 3, boxShadow: 3, borderRadius: 2, bgcolor: '#756c2a' }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                        {item.icon}
                                    </Box>
                                    <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 30, color: '#fff' }}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, color: '#fff', fontSize: 18 }}>
                                        {item.message}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Tenant;
