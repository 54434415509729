import { connect } from 'react-redux';
import storage from 'redux-persist/lib/storage'; // Use localStorage for web
import { persistStore, persistReducer } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit'
import { appName } from '../configs';
import unitsColumns from '../components/Units/columns';
import tenantsColumns from '../components/Tenants/columns';
import utilitiesColumns from '../components/Utilities/columns';
import maintenancesColumns from '../components/Maintenances/columns';
import invoicesColumns from '../components/Invoices/columns';
import paymentsColumns from '../components/Payments/columns';
import arrearsColumns from '../components/Arrears/columns';
import expenditures from '../components/Expenditures/columns';

const Connect = connect,
    initialState = {
        User: null,
        jwtToken: null,
        loggedIn: false,
        firstTime: false,
        properties: [],
        selectedProperty: null,
        drawerOpen: true,
        tablesColumnsFilters: {//change this to tablesColumsFilters
            units: unitsColumns.map(q => q.name).filter(q=>!['description'].includes(q)),
            tenants: tenantsColumns.map(q => q.name),
            utilities: utilitiesColumns.map(q => q.name),
            maintenances: maintenancesColumns.map(q => q.name),
            invoices: invoicesColumns.map(q => q.name),
            payments: paymentsColumns.map(q => q.name),
            arrears: arrearsColumns.map(q => q.name),
            expenditures: arrearsColumns.map(q => q.name)
        },
        loginSelectedPlan:null,
        totalUsers:0
    },
    reducer = (state = initialState, action: any) => {
        const { val, type } = action;
        if (type === 'multiplefields')
            return { ...state, ...val }
        return type === 'logOut' ? initialState : state;
    },
    mapStateToProps = (state: any) => state,
    mapDispatchToProps = (dispatch: any) => {
        return {
            setRedux: (val: any): void => dispatch({ type: 'multiplefields', val }),
            logOut: () => dispatch({ type: 'logOut' })
        };
    },
    persistConfig = {
        key: appName + 'authType',
        storage
    },

    pReducer = persistReducer(persistConfig, reducer),
    store = configureStore({
        reducer: pReducer, middleware: (getDefaultMiddleware: any) => getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false
        })
    }),
    persistor = persistStore(store);
export {
    Connect,
    reducer,
    mapStateToProps,
    mapDispatchToProps,
    persistor,
    store
}