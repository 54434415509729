import { Box, Grid, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { theme } from '../../theme';
import favorite from '../../assets/images/favorite.png'
import { KeyboardDoubleArrowRight } from '@mui/icons-material';

interface Props { }

const HouseSeeker: FC<Props> = () => {


    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.3 } // Adjust threshold to determine when it triggers
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);
    const features = [
        "Browse and filter property listings easily",
        "View verified property details with images",
        "Apply for rentals directly through the app",
        "Secure and hassle-free onboarding process",
        "Make rent and utility payments within the app",
        "Access payment history and download receipts",
        "Receive rent reminders to avoid penalties",
        "Get important updates from landlords",
        "Rate and review properties and landlords",
        "Read tenant reviews before renting",
        "Deposit is secured under Silver & Gold plans"
    ];


    return (
        <section id={'house_seeking'}>
        <Box ref={ref} sx={{
            pl: { sx: 2, md: 10 },
            mt: 10,
            pb: 5,
            overflowX: 'hidden'
        }}>
            <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
                {/* Right Side Text & Boxes with Slide-in Effect */}
                <Grid item xs={12} md={7}>
                    <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        animate={isVisible ? { opacity: 1, x: 0 } : {}}
                        transition={{ duration: 0.8 }}
                        style={{ overflow: 'hidden' }} 
                    >
                        <Typography variant="h4" sx={{
                            ml: { xs: 3, sm: 10 },
                            fontWeight: 'bold',
                            color: theme.lightTheme.palette.primary.main,
                            mb: 5,
                            fontSize: { xs: 20, sm: 30 },
                        }}>
                            Find Your Perfect Home with Ease
                        </Typography>
                        <Typography variant="h1" sx={{
                            ml: { xs: 3, sm: 10 },
                            fontWeight: 'bold',
                            mr: 10,
                            mb: 5,
                            fontSize: { xs: 40, sm: 50 }
                        }}>
                            Your Next Home is Just a Click Away
                        </Typography>
                        <Typography sx={{
                            ml: { xs: 3, sm: 10 },
                            fontSize: { xs: 14, sm: 22 },
                            mr: { xs: 0, sm: 12 },
                            fontWeight: 'bold'
                        }}>
                            With Sheltergram, you can:
                        </Typography>
                        {
                            features.map((desc, i) => (
                                <Box
                                    key={i}
                                    sx={{ ml: { xs: 3, sm: 10 }, mt: 2, display: 'flex' }}
                                >
                                    <KeyboardDoubleArrowRight sx={{ color: theme.lightTheme.palette.primary.main }} />
                                    <Typography>{desc}</Typography>
                                </Box>
                            ))
                        }
                    </motion.div>
                </Grid>
                {/* Left Side Image with Fade-in Effect */}
                <Grid item xs={12} md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <motion.img
                        src={favorite}
                        alt="Your Image"
                        style={{ height: 700, width: "auto", objectFit: "contain", borderRadius: 30 }}
                        initial={{ opacity: 0, y: 50 }}
                        animate={isVisible ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.8 }}
                    />
                </Grid>
            </Grid>
        </Box>
        </section>
    );
}

export default HouseSeeker;
