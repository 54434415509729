import { FC, useEffect, useRef, useState } from "react";
import mainImg from '../../assets/images/main_bg.png'
import peopleImg from '../../assets/images/people.png'
import { Box, Grid, IconButton, Link, Menu, MenuItem, Typography } from "@mui/material";
import logo from '../../assets/images/logo.png'
import { theme } from "../../theme";
import { ArrowForward } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import googleplay from '../../assets/images/googleplay.png'
import iosbtn from '../../assets/images/iosbtn.png'
import RotatingMessages from "./rotatingMessages";
import Investor from "./investor";
import HouseSeeker from "./houseSeeker";
import Tenant from "./tenant";
import Landlord from "./landlord";
import Plans from "./plans";
import Services from "./services";
import Developer from "./developers";
import Footer from "./footer";
import CountUp from 'react-countup';
import { Apis } from "../../helpers";
import Show from "../Common/show";
import { Connect, mapDispatchToProps, mapStateToProps } from "../../Redux";

interface StateProps {
    showMenu: boolean
    scrolled: boolean

}
interface Props {
    setRedux?: (data: any) => void,
    totalUsers?: number
}

const Landing: FC<Props> = ({
    setRedux,
    totalUsers
}) => {
    const links = [
        { label: 'Home', url: '#home' },
        { label: 'Investor', url: '#investor' },
        { label: 'Developer', url: '#developer' },
        { label: 'House Seeking', url: '#house_seeking' },
        { label: 'Tenant', url: '#tenant' },
        { label: 'Landlord', url: '#landlord' },
        { label: 'Services', url: '#services' },
        { label: 'Contact', url: '#contact' }
        // { label: 'Get Started', url: '/auth' }
    ];
    const [state, setState] = useState<StateProps>({
        showMenu: false,
        scrolled: false,
    })
    const { showMenu, scrolled } = state
    const handleMenuOpen = () => {
        setState({ ...state, showMenu: !showMenu });
    };
    const menuRef = useRef(null);
    // Close menu when clicking outside
    const getNumberOfUsers = () => {
        Apis({
            module: 'Users',
            action: 'getNumberOfUsers'
        }).then((totalUsers: any) => {
            if (setRedux)
                setRedux({ totalUsers })
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        getNumberOfUsers()
        const handleScroll = () => {
            //@ts-ignore
            setState({ scrolled: window.scrollY > 10 ? true : false });
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            //@ts-ignore
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setState({ ...state, showMenu: false });
            }
        };

        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    return (
        <div style={{
        }}>
            <section id={'home'}>
                <Box
                    sx={{
                        backgroundImage: `url(${mainImg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        minHeight: "85vh",
                        height: { xs: '90vh', sm: '100vh' }
                    }}
                >

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: scrolled ? 'fixed' : 'relative',
                        width: '100%',
                        backgroundColor: scrolled ? 'white' : 'transparent',
                        zIndex: 1000,
                        boxShadow: scrolled ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none'
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: 2,
                            }}
                        >
                            <img style={{
                                height: 70,
                                width: 70
                            }} src={logo} />
                            <Typography sx={{ fontSize: { xs: 18, md: 30 }, fontWeight: 'bold', color: theme.lightTheme.palette.primary.main }}>Sheltergram</Typography>
                        </Box>
                        {/* Links Section - Hidden on Small Screens */}
                        <Box sx={{ display: { xs: 'none', sm: "none", md: 'none', lg: "flex" }, alignItems: 'center' }}>
                            {
                                links.map(({ label, url }) => {
                                    // let obj: any = {}
                                    // if (label === 'Get Started')
                                    //     obj = {
                                    //         bgcolor: theme.lightTheme.palette.primary.main,
                                    //         color: '#fff',
                                    //         p: 1.5,
                                    //         borderRadius: 20,
                                    //         fontWeight: 'bold'
                                    //     }
                                    return (
                                        <Link sx={{
                                            fontSize: 18,
                                            ml: 2,
                                            mr: 2,
                                            textDecoration: 'none',
                                            color: '#000',
                                        }} key={label} href={url}>{label}</Link>
                                    )
                                })
                            }
                        </Box>
                        <Box sx={{ pr: 2, display: 'flex', alignItems: 'center' }} >
                            <Link sx={{
                                fontSize: 22,
                                ml: 2,
                                mr: 2,
                                textDecoration: 'none',
                                color: '#000',
                                display: { xs: 'none', sm: 'flex' }
                            }} href={'/auth'}>Login</Link>
                            <Link
                                sx={{
                                    bgcolor: theme.lightTheme.palette.primary.main,
                                    color: '#fff',
                                    p: 1.5,
                                    pl: 3,
                                    pr: 3,
                                    borderRadius: 3,
                                    fontWeight: 'bold',
                                    display: { xs: 'none', sm: 'flex' },
                                    alignItems: 'center'
                                }}
                                href={'/auth?signup=true'}
                            >
                                Join Now
                                <ArrowForward />
                            </Link>
                            <Box sx={{ display: { xs: 'flex', lg: 'none' } }}>
                                <IconButton sx={{ ml: 2 }} edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                                    <MenuIcon color='primary' sx={{ fontSize: 30 }} />
                                </IconButton>
                            </Box>
                        </Box>
                        {/* Accordion Menu for Small Screens */}
                        <Box
                            ref={menuRef}
                            sx={{
                                backgroundImage: `url(${mainImg})`,
                                position: 'absolute',
                                top: '100px',
                                left: 0,
                                width: '100%',
                                // backgroundColor: '#fff',
                                boxShadow: 2,
                                p: 2,
                                zIndex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                maxHeight: showMenu ? '300px' : '0px',  // Smooth height transition
                                opacity: showMenu ? 1 : 0,  // Fade effect
                                transform: showMenu ? 'translateY(0)' : 'translateY(-10px)', // Slide effect
                                transition: 'max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease',
                                overflow: 'hidden',  // Prevent content overflow during animation
                                overflowY: 'auto',
                                pb: { xs: 0, sm: 2 },
                                pl: 0
                            }}
                        >
                            {links.map(({ label, url }, i) => (
                                <MenuItem
                                    sx={{ borderBottom: i === links.length - 1 ? 'transparent' : '1px solid #e9e9e9' }}
                                    key={label}
                                    component="a" href={url}
                                    onClick={() => setState({ ...state, showMenu: false })}
                                >
                                    {label}
                                </MenuItem>
                            ))}
                            <MenuItem sx={{
                                display: { xs: 'block', sm: 'none' },
                                borderTop: '1px solid #e9e9e9'
                            }} component="a" href={'/auth'}>
                                Login
                            </MenuItem>
                            <MenuItem sx={{
                                display: { xs: 'block', sm: 'none' },
                                bgcolor: theme.lightTheme.palette.primary.main,
                                color: '#fff',
                                pt: 1.5,
                            }} component="a" href={'/auth?signup=true'}>
                                Join Now
                            </MenuItem>
                        </Box>

                    </div>


                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={7}
                        >
                            <Typography variant="h4" sx={{
                                ml: { xs: 3, sm: 10 },
                                fontWeight: 'bold',
                                color: theme.lightTheme.palette.primary.main,
                                mb: 2,
                                fontSize: { xs: 20, sm: 25 },
                                //  mt:{xs:-15, sm:0}
                            }}>
                                Smart.Simple.Accessible
                            </Typography>
                            <RotatingMessages />

                            <Box sx={{
                                ml: { xs: 3, sm: 10 },
                                mt: { xs: 4, md: 5 }
                            }
                            }>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 35,
                                        background: 'linear-gradient(45deg, #ff6b6b, #6b7bff)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                        animation: 'pulse 2s infinite'
                                    }}
                                >
                                    Join <Show>
                                        <Show.When isTrue={Boolean(totalUsers)}>
                                            <CountUp start={0} end={totalUsers as number} duration={3} />
                                        </Show.When>
                                    </Show> happy users! 🚀
                                </Typography>

                                <Typography variant="h5" sx={{
                                    fontSize: { xs: 18, fontWeight: 'bold' }
                                }}>Download App On</Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 16, // Space between buttons
                                        flexWrap: 'nowrap', // Ensures they stay in the same row
                                    }}
                                >
                                    <a
                                        href="https://apps.apple.com/us/app/sheltergram/id6698868923"
                                        style={{ marginTop: 16, display: 'block' }}
                                    >
                                        <img
                                            src={iosbtn}
                                            alt="Apple App Store"
                                            style={{
                                                width: 'min(250px, 40vw)',
                                                height: 'auto',
                                                borderRadius: 8,
                                            }}
                                        />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.sheltergram.co"
                                        style={{ marginTop: 16, display: 'block' }}
                                    >
                                        <img
                                            src={googleplay}
                                            alt="Google Play Store"
                                            style={{
                                                width: 'min(250px, 40vw)', // Adjusts based on screen size
                                                height: 'auto', // Keeps aspect ratio
                                                borderRadius: 8,
                                            }}
                                        />
                                    </a>
                                </div>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            sx={{ display: { xs: "none", md: "block" } }}
                        >
                            <img
                                src={peopleImg}
                                alt="Your Image"
                                style={{ width: "100%", objectFit: "cover" }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </section>
            <Investor />
            <Developer />
            <HouseSeeker />
            <Tenant />
            <Landlord />
            <Plans />
            <Services />
            <Footer />
        </div>
    )
}
export default Connect(mapStateToProps, mapDispatchToProps)(Landing)
