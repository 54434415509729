import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import { theme } from "../../theme";

const messages = [
  {
    title: "Investing Made Simple & Transparent",
    message: "Start building your financial future today. Explore real estate opportunities worldwide and grow your wealth effortlessly.",
  },
  {
    title: "Smart & Hassle-Free Tenant Management",
    message: "Say goodbye to manual record-keeping! Track rent payments, list properties with ease, and manage tenants seamlessly—all in one place.",
  },
  {
    title: "Instant & Reliable Tenant Credit Access",
    message: "Struggling with rent this month? Get fast and secure credit access to cover your rent without hassle. Stay worry-free and keep your home stress-free!",
  },
];
  

const RotatingMessages = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ overflow: "hidden", width: "100%" }}> {/* Prevents horizontal scroll */}
      {/* Title Animation - Swipe Effect */}
      <AnimatePresence mode="wait">
        <motion.div
          key={messages[index].title}
          initial={{ x: 100, opacity: 0 }} // Start from right
          animate={{ x: 0, opacity: 1 }} // Move to center
          exit={{ x: -100, opacity: 0 }} // Exit to left
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h1"
            sx={{
              ml: { xs: 3, sm: 10 },
              fontWeight: "bold",
              mr: 10,
              mb: 5,
              fontSize: { xs: 40, sm: 60 },
            }}
          >
            {messages[index].title.split(" ").map((word, i) =>
              i === 2 || i === 4 ? (
                <span key={i} style={{ color: theme.lightTheme.palette.primary.main }}>
                  {word}{" "}
                </span>
              ) : (
                word + " "
              )
            )}
          </Typography>
        </motion.div>
      </AnimatePresence>

      {/* Message Animation - Fade & Scale Effect */}
      <AnimatePresence mode="wait">
        <motion.div
          key={messages[index].message}
          initial={{ opacity: 0, scale: 0.9 }} // Start faded & smaller
          animate={{ opacity: 1, scale: 1 }} // Fully visible & normal size
          exit={{ opacity: 0, scale: 1.1 }} // Fades out & slightly enlarges
          transition={{ duration: 0.8 }}
        >
          <Typography
            sx={{
              ml: { xs: 3, sm: 10 },
              fontSize: { xs: 16, sm: 20 },
              mr: { xs: 0, sm: 12 },
            }}
          >
            {messages[index].message}
          </Typography>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default RotatingMessages;
