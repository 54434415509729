import { Box, Grid, Typography, TextField, Button, Divider, Link, Container, CircularProgress } from "@mui/material";
import { Email, Phone, LocationOn, Facebook, X, Instagram, LinkedIn } from "@mui/icons-material";
import Copyright from "../Common/Copyright";
import { LoadingButton } from "@mui/lab";
import { useRef, useState } from "react";
import { Apis } from "../../helpers";
import { EventsEmit } from "../../configs/events";
import logo from '../../assets/images/logo.png'
import { theme } from "../../theme";

interface Stateprops {
    submitting: boolean
}
const TikTokIcon = ({ color = "#000000" }) => {
    return (
        <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="100%"
            height="100%"
        >
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
        </svg>
    );
};


const Footer = () => {
    const [state, setState] = useState<Stateprops>({
        submitting: false
    })
    const { submitting } = state
    const formRef = useRef<HTMLFormElement>(null)

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevents default form submission
        const formData = new FormData(event.currentTarget);
        const data = Object.fromEntries(formData.entries());
        setState({ ...state, submitting: true })
        Apis({
            action: 'userQuery',
            module: 'General',
            ...data
        }).then(({ message }: any) => {
            setState({ ...state, submitting: false })
            EventsEmit('app', {
                info: {
                    message,
                    status: 'success'
                }
            })
            formRef.current?.reset()
        }).catch(() => {
            setState({ ...state, submitting: false })
            EventsEmit('app', {
                info: {
                    message: "An error occurred, please try again later.",
                    status: 'error'
                }
            })
        })
    };
    return (
        <section id={'contact'} >
            <Box sx={{ bgcolor: '#1c1c1c', color: '#e0e0e0', pt: 6, pb: 4 }}>
                <Container maxWidth="md">
                    {/* Contact Form - Full Row, Centered */}
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <Box sx={{ p: 4, bgcolor: '#252525', borderRadius: 2, boxShadow: 2 }}>
                                <Typography variant="h6" gutterBottom textAlign="center" fontWeight="bold">
                                    Get in Touch
                                </Typography>
                                <form
                                    autoComplete="off"
                                    onSubmit={submit}
                                    ref={formRef}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Your Name"
                                                variant="outlined"
                                                required
                                                name={"username"}
                                                sx={{
                                                    input: { color: "#fff" },
                                                    label: { color: "#bbb" },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: "#888" }, // Grey border when not focused
                                                        "&:hover fieldset": { borderColor: "#aaa" }, // Slightly lighter on hover
                                                        "&.Mui-focused fieldset": { borderColor: "#1976d2" } // Default blue on focus
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Your Email"
                                                variant="outlined"
                                                name={"email"}
                                                required
                                                type="email"
                                                sx={{
                                                    input: { color: "#fff" },
                                                    label: { color: "#bbb" },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: "#888" },
                                                        "&:hover fieldset": { borderColor: "#aaa" },
                                                        "&.Mui-focused fieldset": { borderColor: "#1976d2" }
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Subject"
                                                required
                                                variant="outlined"
                                                name={"subject"}
                                                sx={{
                                                    input: { color: "#fff" },
                                                    label: { color: "#bbb" },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: "#888" },
                                                        "&:hover fieldset": { borderColor: "#aaa" },
                                                        "&.Mui-focused fieldset": { borderColor: "#1976d2" }
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Message"
                                                variant="outlined"
                                                multiline
                                                name={"message"}
                                                required
                                                rows={4}
                                                sx={{
                                                    "& .MuiInputBase-root": { color: "#fff" }, // Text color
                                                    "& .MuiInputLabel-root": { color: "#bbb" }, // Label color
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: "#888" },
                                                        "&:hover fieldset": { borderColor: "#aaa" },
                                                        "&.Mui-focused fieldset": { borderColor: "#1976d2" }
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                sx={{
                                                    textTransform: 'none',
                                                    fontSize: '16px',
                                                    px: 4,
                                                    py: 1
                                                }}
                                                loading={submitting}
                                                loadingIndicator={<CircularProgress size={24} sx={{ color: '#fff' }} />} // White loader
                                            >
                                                Send Message
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Divider for Separation */}
                    <Divider sx={{ my: 4, bgcolor: "#444" }} />

                    {/* Quick Links & Contact Info - Centered */}
                    <Grid container spacing={4} justifyContent="center">
                        {/* Quick Links */}
                        <Grid item xs={12} md={4} textAlign="center">
                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                Quick Links
                            </Typography>
                            {[
                                { label: "Terms & Conditions", url: "/terms_conditions" },
                                { label: "Privacy Policy", url: "/privacy_policies" },
                                { label: "FAQs", url: "/faqs" }
                            ].map(({ label, url }) => (
                                <Typography sx={{ my: 1 }} key={label}>
                                    <Link href={url} color="inherit" sx={{ textDecoration: 'none', fontSize: "16px" }}>
                                        {label}
                                    </Link>
                                </Typography>
                            ))}
                        </Grid>

                        {/* Contact Information */}
                        <Grid item xs={12} md={4} textAlign="center">
                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                Contact Information
                            </Typography>
                            <Typography variant="body1" sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 1 }}>
                                <LocationOn sx={{ mr: 1, color: "lightblue" }} />
                                Ola Plaza, Muthaiga, Nairobi, Kenya
                            </Typography>
                            <Typography variant="body1" sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 1 }}>
                                <Phone sx={{ mr: 1, color: "lightgreen" }} />
                                <Link href="tel:+254786481885" color="inherit" sx={{ textDecoration: 'none' }}>
                                    +254 786 481 885
                                </Link>
                            </Typography>
                            <Typography variant="body1" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Email sx={{ mr: 1, color: "orange" }} />
                                <Link href="mailto:info@sheltergram.co" color="inherit" sx={{ textDecoration: 'none' }}>
                                    info@sheltergram.co
                                </Link>
                            </Typography>
                        </Grid>

                        {/* Social Media Icons */}
                        <Grid item xs={12} md={4} textAlign="center">
                            <Box sx={{display:'flex', alignItems:'center'}}>
                                <img src={logo} style={{height:60}} />
                            <Typography sx={{fontSize:20, color:theme.lightTheme.palette.primary.main}}>
                                Sheltergram
                            </Typography>
                            </Box>
                            <Typography sx={{mb:2, color:'#8e8e8e', textAlign:'left'}}>Find your next home, manage rentals effortlessly, and make smarter property investments—all in one place.</Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                                <Link href="https://www.facebook.com/profile.php?id=61565409220193&_rdc=1&_rdr#" target="_blank">
                                    <Facebook sx={{ fontSize: 32, color: "#1877F2" }} />
                                </Link>
                                <Link href="https://x.com/sheltergram_ke" target="_blank">
                                    <X sx={{ fontSize: 32, color: "#1DA1F2" }} />
                                </Link>
                                <Link href="https://www.instagram.com/sheltergram.co/" target="_blank">
                                    <Instagram sx={{ fontSize: 32, color: "#C13584" }} />
                                </Link>
                                <Link href="https://www.linkedin.com/company/sheltergram/" target="_blank">
                                    <LinkedIn sx={{ fontSize: 32, color: "#0077B5" }} />
                                </Link>
                                <Link href="https://www.tiktok.com/@sheltergram" target="_blank">
                                    <div style={{ width: "30px" }}>
                                        <TikTokIcon color="#fff" />
                                    </div>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                    <Copyright color={'#929292'} mt={2} />
                </Container>
            </Box>
        </section>
    );
};

export default Footer;
