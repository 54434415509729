import { Box, Grid, Typography } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import marketTrend from '../../assets/images/marketTrend.png';
import { theme } from '../../theme';
import browse from '../../assets/images/browse.png';
import invest from '../../assets/images/invest.png';
import earn from '../../assets/images/earn.png';

interface Props { }

const Investor: FC<Props> = () => {
    const details = [
        { image: browse, title: 'Browse Properties', message: 'Select a property that fits your goal from our huge variety of hand-picked properties.' },
        { image: invest, title: 'View Details & Invest', message: 'View essential property analytics, from rental yield to market trends, and invest with clarity.' },
        { image: earn, title: 'Earn and Track', message: 'Gain full visibility into your portfolio’s performance and monitor total returns effortlessly.' }
    ];

    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.3 } // Adjust threshold to determine when it triggers
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <section id={'investor'}>
        <Box
            ref={ref}
            sx={{
                mt: { sx: 10, md: 20 },
                overflowX: 'hidden',  // Prevents horizontal scrolling
                position: 'relative'   // Keeps children within bounds
            }}
        >
            <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{ height: "100%", overflowX: 'hidden' }} // Additional safeguard
            >
                {/* Left Side Image with Fade-in Effect */}
                <Grid item xs={12} md={5}>
                    <motion.img
                        src={marketTrend}
                        alt="Your Image"
                        style={{ width: "100%", objectFit: "cover" }}
                        initial={{ opacity: 0, y: 50 }}
                        animate={isVisible ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.8 }}
                    />
                </Grid>

                {/* Right Side Text & Boxes with Slide-in Effect */}
                <Grid item xs={12} md={7}>
                    <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        animate={isVisible ? { opacity: 1, x: 0 } : {}}
                        transition={{ duration: 0.8 }}
                        style={{ width: "100%" }}  // Ensures no overflow
                    >
                        <Typography variant="h4" sx={{
                            ml: { xs: 3, sm: 10 },
                            fontWeight: 'bold',
                            color: theme.lightTheme.palette.primary.main,
                            mb: 5,
                            fontSize: { xs: 20, sm: 30 },
                        }}>
                            Global Real Estate Investment Made Easier
                        </Typography>
                        <Typography variant="h1" sx={{
                            ml: { xs: 3, sm: 10 },
                            fontWeight: 'bold',
                            mr: 10,
                            mb: 5,
                            fontSize: { xs: 40, sm: 50 }
                        }}>
                            The Future of Real Estate Investment is Here
                        </Typography>
                        <Typography sx={{
                            ml: { xs: 3, sm: 10 },
                            fontSize: { xs: 14, sm: 18 },
                            mr: { xs: 0, sm: 12 }
                        }}>
                            By leveraging the power of blockchain, you can seamlessly invest USDC or EURC in high-potential real estate projects under development—secure, transparent, and borderless.
                            Whether you're abroad and looking to build your dream house back home, we connect you with trusted developers who ensure your project is handled professionally. Track progress remotely and make secure payments with ease—transparent, reliable, and built to your vision.
                        </Typography>
                    </motion.div>
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(auto-fit, minmax(250px, 1fr))" }, // Wraps on small screens, stays in one row on large screens
                    gap: 4, // Spacing between grid items
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                {details.map(({ image, title, message }, i) => (
                    <motion.div
                        key={i}
                        initial={{ opacity: 0, y: 50 }}
                        animate={isVisible ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                                p: { xs: 2, sm: 3 },
                                maxWidth: 400, // Prevents items from being too wide
                                mx: "auto", // Centers items horizontally
                            }}
                        >
                            {/* Image and Number Container */}
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                <img
                                    src={image}
                                    style={{
                                        width: "min(200px, 30vw)",
                                        height: "auto",
                                    }}
                                />
                                <Box
                                    sx={{
                                        backgroundColor: theme.lightTheme.palette.primary.main,
                                        height: 40,
                                        width: 40,
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography sx={{ color: "#fff", fontWeight: "bold", fontSize: 18 }}>
                                        0{i + 1}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Title and Message */}
                            <Typography sx={{ fontSize: 20, fontWeight: "bold", mt: 2 }}>
                                {title}
                            </Typography>
                            <Typography sx={{ maxWidth: "90%", mt: 1 }}>{message}</Typography>
                        </Box>
                    </motion.div>
                ))}
            </Box>
        </Box>
        </section>
    );
}

export default Investor;
