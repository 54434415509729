import { Box, Grid, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import developer from '../../assets/images/developer.png';
import { theme } from '../../theme';
interface Props { }

const Developer: FC<Props> = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.3 } // Adjust threshold to determine when it triggers
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <section id={'developer'}>
            <Box
                ref={ref}
                sx={{
                    mt: { sx: 10, md: 20 },
                    overflowX: 'hidden',  // Prevents horizontal scrolling
                    position: 'relative',   // Keeps children within bounds
                    backgroundImage: "linear-gradient(135deg, #f9f9f0 0%, #c1c196 100%)",
                    pt: 5,
                    pb: { sx: 5, md: 10 }
                }}
            >
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "100%", overflowX: 'hidden' }} // Additional safeguard
                >
                    <Grid item xs={12} md={7}>
                        <motion.div
                            initial={{ opacity: 0, x: 50 }}
                            animate={isVisible ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.8 }}
                            style={{ width: "100%" }}  // Ensures no overflow
                        >
                            <Typography variant="h4" sx={{
                                ml: { xs: 3, sm: 10 },
                                mr: { xs: 2, sm: 0 },
                                fontWeight: 'bold',
                                color: theme.lightTheme.palette.primary.main,
                                mb: 5,
                                fontSize: { xs: 20, sm: 30 },
                            }}>
                                Unlock Global Investment for Your Real Estate Projects
                            </Typography>
                            <Typography variant="h1" sx={{
                                ml: { xs: 3, sm: 10 },
                                fontWeight: 'bold',
                                mr: 10,
                                mb: 5,
                                fontSize: { xs: 40, sm: 50 }
                            }}>
                                The Future of Property Development is Here
                            </Typography>
                            <Typography sx={{
                                ml: { xs: 3, sm: 10 },
                                fontSize: { xs: 14, sm: 20 },
                                mr: { xs: 0, sm: 12 }
                            }}>
                                Sheltergram connects property developers with global investors through blockchain-powered funding.
                                Raise capital seamlessly with secure, transparent, and borderless investments in **USDC and EURC**.
                                Showcase your developments to international buyers and investors looking for high-potential real estate opportunities.
                                Manage bookings, track project progress, and receive secure payments—all on a trusted platform designed for modern real estate.
                            </Typography>
                        </motion.div>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <motion.img
                            src={developer}
                            alt="Your Image"
                            style={{ width: "100%", objectFit: "cover" }}
                            initial={{ opacity: 0, y: 50 }}
                            animate={isVisible ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.8 }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
}

export default Developer;
